import { AnimatePresence, motion } from 'framer-motion';
import Lottie from 'lottie-react';
import ProcessingLottie from '@/../../public/assets/lotties/processing.json';
import { twMerge } from 'tailwind-merge';

const Processing = ({ loading = false, className, ...properties }) => (
    <AnimatePresence>
        {loading && (
            <motion.div className={twMerge('absolute top-0 left-0 h-full w-full flex items-center justify-center bg-white/10 backdrop-blur-sm z-10 rounded-md', className)} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ delay: 0.2 }} {...properties}>
                <Lottie className={'h-60 aspect-square'} animationData={ProcessingLottie} speed={5} autoplay loop />
            </motion.div>
        )}
    </AnimatePresence>
);

export default Processing;
